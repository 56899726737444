<template>
    <!-- فورم إضافة دروس  -->
 
    <ek-dialog
        ref="dialog"
        btnText="درس جديد"
        title="درس جديد"
        placeholder="ابحث حسب الاسم،اسم الصف،اسم المادة،اسم المدرس،الوحدة..."
        @ok="submitLesson"
        @close="resetForm"
        @search="setSearch"
    >
        <template #body>
            <b-form-group label="نوع الدرس" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    :value="newLessonType"
                    class="d-flex flex-wrap"
                    style="gap: 20px"
                    :options="
                        lessonOptions.map((el) => ({
                            text: el.name,
                            value: el.id,
                        }))
                    "
                    :aria-describedby="ariaDescribedby"
                    @change="SET_NEW_LESSON_TYPE({ newLessonType: $event })"
                    name="radio-options"
                >
                </b-form-radio-group>
            </b-form-group>
            <ValidationObserver ref="form">
                <b-form-checkbox
                    v-if="newLessonType == 1"
                    id="checkbox-1"
                    v-model="status"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                >
                    بث مباشر
                </b-form-checkbox>

                <ek-input-text
                    v-model="lessonForm.name"
                    name="اسم الدرس"
                    placeholder="مثال : الجمع والضرب "
                    label="اسم الدرس"
                    :rules="[
                        {
                            type: 'required',
                            message: 'اسم الدرس مطلوب',
                        },
                    ]"
                ></ek-input-text>

                <ek-input-textarea
                    v-if="newLessonType != 3"
                    v-model="lessonForm.description"
                    name="وصف الدرس"
                    placeholder="ادخل وصف الدرس "
                    label="الوصف"
                    :rules="[
                        {
                            type: 'required',
                            message: 'وصف الدرس مطلوب',
                        },
                    ]"
                ></ek-input-textarea>
                <!-- {{ lessonForm }} -->
                <ek-input-select
                    :options="chapterOptions"
                    v-model="lessonForm.chapterId"
                    name="تابع للبحث"
                    placeholder="اختر البحث "
                    label="تابع للبحث"
                    :rules="[
                        {
                            type: 'required',
                            message: 'بحث الدرس مطلوبة',
                        },
                    ]"
                ></ek-input-select>
   <b-col lg="4" md="6">
                        <b-form-group label="حالة الدرس">
                            <b-form-radio-group
                                id="radio-isHidden"
                                :options="options"
                                v-model="lessonForm.isFree"
                                name="حالة الدرس"
                            >
                            </b-form-radio-group>
                        </b-form-group>
                    </b-col>
                <ek-input-text
                    v-model="lessonForm.order"
                    type="number"
                    name="الترتيب"
                    placeholder="ترتيب الدرس "
                    label="الترتيب"
                    :rules="[
                        {
                            type: 'required',
                            message: 'ترتيب الدرس مطلوب',
                        },
                    ]"
                ></ek-input-text>

                <ek-input-text
                    v-if="newLessonType == 1 && status == 'accepted'"
                    v-model="lessonForm.url"
                    name="رابط الفيديو"
                    placeholder="ادخل رابط الفيديو "
                    label="رابط الفيديو"
                    :rules="[
                        {
                            type: 'required',
                            message: 'رابط الفيديو مطلوب',
                        },
                    ]"
                ></ek-input-text>

                <ek-date-picker
                    v-if="newLessonType == 1 && status == 'accepted'"
                    v-model="lessonForm.scheduleDate"
                    name=" date"
                    placeholder=" ادخل تاريخ بدء البث "
                    label=" تاريخ بدء البث"
                    clock="true"
                ></ek-date-picker>

                <ek-input-image
                    accept="image/*"
                    name="cover"
                    ref="thumbUpload"
                    v-if="newLessonType == 1 && status == 'not_accepted'"
                    @input="getLessonThumb"
                >
                    <h5>غلاف الدرس</h5>
                </ek-input-image>

                <ek-input-image
                    ref="videoUpload"
                    name="file"
                    accept="video/*,.pdf"
                    v-if="newLessonType < 3 && status == 'not_accepted'"
                    @input="getLessonFile"
                >
                    <h5>
                        {{
                            newLessonType == 1
                                ? "ملف الفيديو"
                                : "اسحب الملف او انقر للتحميل"
                        }}
                    </h5>
                </ek-input-image>
            </ValidationObserver>
            <b-progress
                slize="lg"
                height="20px"
                v-if="progress !== null"
                class="mt-1"
                :value="progress"
                :max="100"
                show-progress
                animated
            >
            </b-progress>
        </template>
    </ek-dialog>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { BToast } from "bootstrap-vue";
export default {
    props: {
        btnText: {
            default: "مستند جديد",
            type: String,
        },
        id: String,
    },
    
    data() {
        return {
            options: [
                { text: "مجاني", value: true },
                { text: "مدفوع حسب الباقة", value: false },
            ],
            save: false,
            status: "not_accepted",
        };
    },
    computed: {
        ...mapState({
            unitsOptions: ({ units }) => units.unitsOptions,
            chapterOptions: ({ chapter }) => chapter.chapterOptions,

            lessonOptions: ({ lessons }) => lessons.lessonOptions,
            lessonForm: ({ lessons }) => lessons.lessonForm,
            lessonUnit: ({ lessons }) => lessons.lessonForm.unitId,
            unitDetailDto: ({ units }) => units.unitDetailDto,
            newLessonType: ({ lessons }) => lessons.newLessonType,
            progress: ({ lessons }) => lessons.progress,
        }),
    },
    methods: {
        ...mapActions([
            "addVideoLesson",
            "addFileLesson",
            "addLiveLesson",
            "addQuizLesson",
            "getUnitsOptions",
            "getChapterOptions",
            "getSubjectOptions",
            "getLessons",
        ]),
        ...mapMutations([
            "SET_NEW_LESSON_TYPE",
            "ADD_UNIT_LESSON",
            "RESET_LESSON_FORM",
        ]),
        onAddLesson(res) {
            this.save = false;
            this.$refs.dialog.close();

            //
            this.resetForm();
            this.ADD_UNIT_LESSON(res);
            this.getUnitsOptions();
            this.getChapterOptions();
        },
        resetForm() {
            this.$refs.form.reset();
            if (this.$refs.thumbUpload) this.$refs.thumbUpload.reset();
            if (this.$refs.videoUpload) this.$refs.videoUpload.reset();
            this.RESET_LESSON_FORM({ unitId: this.id });
        },
        submitLesson() {
            console.log("save");
            this.$refs.form.validate().then((s) => {
                if (
                    s &&
                    (this.lessonForm.file ||
                        this.newLessonType == 3 ||
                        this.status == "accepted")
                ) {
                    // ? مقطع فيديو
                    if (
                        this.newLessonType == 1 &&
                        this.status == "not_accepted"
                    ) {
                        this.save = true;
                        this.addVideoLesson(this.lessonForm).then(
                            this.onAddLesson
                        ),
                            () => {
                                this.save = true;
                            };
                        // ? مستند
                    } else if (this.newLessonType == 2) {
                        this.addFileLesson(this.lessonForm).then(
                            this.onAddLesson
                        );

                        // ? اختبار
                    } else if (this.newLessonType == 3) {
                        this.addQuizLesson({
                            name: this.lessonForm.name,
                            unitId: this.lessonForm.unitId,
                            chapterId: this.lessonForm.chapterId,
                            isFree: this.lessonForm.isFree,

                            order: this.lessonForm.order,
                        }).then(this.onAddLesson),
                            () => {
                                this.$refs.dialog.close();
                                this.$refs.form.reset();
                            };
                    } else if (
                        this.newLessonType == 1 &&
                        this.status == "accepted"
                    ) {
                        this.addLiveLesson({
                            unitId: this.lessonForm.unitId,
                            chapterId: this.lessonForm.chapterId,
                            isFree: this.lessonForm.isFree,

                            name: this.lessonForm.name,
                            description: this.lessonForm.description,
                            order: this.lessonForm.order,
                            scheduleDate: this.lessonForm.scheduleDate.replace(
                                " ",
                                "T"
                            ),
                            url: this.lessonForm.url,
                        }).then(this.onAddLesson),
                            () => {
                                this.$refs.dialog.close();
                                this.$refs.form.reset();
                            };
                    }
                } else {
                    const bToast = new BToast();
                    bToast.$bvToast.toast("يرجى اختيار ملف", {
                        title: "الملف  مطلوب",
                        variant: "danger",
                        toaster: "b-toaster-bottom-right",
                        solid: true,
                        autoHideDelay: 2000,
                        appendToast: true,
                    });
                }
            });
        },
        getLessonFile(e) {
            if (e && e.file) {
                this.lessonForm.file = e.file;
            }
        },
        getLessonThumb(e) {
            if (e) this.lessonForm.thumbFile = e.file;
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: [
                    "className",
                    "lessonType",
                    "name",
                    "semester",
                    "subjectName",
                    "teacherName",
                    "unitName",
                ],
                query,
            });
        },
    },
    watch: {
        lessonUnit(nv) {
            const unit = this.unitsOptions.find((u) => u.id == nv);
            this.lessonForm.order = unit ? unit.order : 1;
        },
    },
    created() {
        this.getChapterOptions(this.id);
    },
};
</script>
